import 'babel-polyfill'
import "./js/core/events"
import "./js/core/navigation"
import "./js/core/navigation_callbacks"
import "@fancyapps/fancybox/dist/jquery.fancybox.min.css"

import("jquery").then(async ($)=>{
   window.$ = window.jQuery = $
   const fb = await import("@fancyapps/fancybox")
})



